import React from 'react'
import {
  FaGithubSquare,
  FaInstagram,
} from 'react-icons/fa'

const Footer = () => {
  const instagramLink = 'https://www.instagram.com/brillianteducationid/'
  const githubLink = 'https://github.com/cahyajuliannn'

  const handleInstagramClick = () => {
    window.location.href = instagramLink;
  };

  const handleGithubClick = () => {
    window.location.href = githubLink;
  };
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 gap-8 text-gray-300'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Brilliant.</h1>
        <p className='py-4'>"This serves as my personal website. It originated in 2019 with the intention of establishing an educational platform focused on Physics and Mathematics lessons designed for junior and senior high school students. However, as time progressed, I immersed myself in the realm of Information Technology, discovering immense satisfaction in this field. 
        Consequently, I have chosen to divulge more about my experiences within this domain."</p>
        <div className='flex justify-between md:w-[8%] my-6'>
            <FaInstagram size={30} onClick={handleInstagramClick} />
            <FaGithubSquare size={30} onClick={handleGithubClick}/>
        </div>
      </div>
    </div>
  );
}
export default Footer
