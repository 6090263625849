import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import NavBar from './Pages/NavBar'
import Home from './Pages/Home'
import CloudComputing from './Pages/CloudComputing.jsx'
import CloudIntro from "./Blogs/CloudIntro.jsx"
import BlogSections from "./Blogs/BlogSections.jsx"
import NotFound from "./Components/NotFound.jsx"
import Footer from "./Components/Footer.jsx"
import JenkinsDocker from "./Blogs/JenkinsDocker.jsx"
import JenkinsDockeSSH from "./Blogs/JenkinsDockerSSH.jsx"
import MySQLBackup from "./Blogs/MySQLBackup.jsx"
function App() {
  // Get the current pathname from the location object
  const currentPath = window.location.pathname;

  // Define an array of valid paths
  const validPaths = ['/', '/articles', '/cloud-intro', '/learning-journey-started', '/jenkins-docker','/docker-ssh','/mysql-backup'];

  // Check if the current path is in the array of valid paths
  const showNavBar = validPaths.includes(currentPath);

  return (
    <div>
      {showNavBar && <NavBar />}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learning-journey-started" element={<CloudComputing />} />
          <Route path="/cloud-intro" element={<CloudIntro />} />
          <Route path="/articles" element={<BlogSections />} />

          <Route path="/jenkins-docker" element={<JenkinsDocker />}/>
          <Route path="/docker-ssh" element={<JenkinsDockeSSH />}/>
          <Route path="/mysql-backup" element={<MySQLBackup />}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
