import React from 'react'
import {TypeAnimation} from 'react-type-animation'

const Hero = () => {
  return (
    <div className='text-white'>
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <p1 className='text-[#00df9a] font-bold p-2'>SKILL UP WITH ME</p1>
            <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>Let's grow together and learn with me</h1>
            <div className='flex justify-center items-center pb-2'>
                <TypeAnimation
                    className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
                    sequence={[
                    "Cloud Engineering",
                    2500,
                    "Kubernetes",
                    2500,
                    "Java & .Net Development",
                    2500,
                    "IT Architecture",
                    2500,
                    "Performance Test",
                    2500,
                    "DevSecOps",
                    2500,
                    "Database",
                    2500
                    ]}
                    speed={220}
                    repeat={Infinity}
                />
            </div>
            <p>"The capacity to learn is a gift; the ability to learn is a skill; the willingness to learn is a choice." - Brian Herbert</p>
            <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'><a href='/learning-journey-started'>Click Have Fun</a></button>
        </div>
    </div>
  )
}

export default Hero