import React from 'react'
import Laptop from '../Assets/laptop.png'
import { useNavigate } from 'react-router-dom';

const CloudComputing = () => {
  const navigate = useNavigate()
    return (
        <div className='w-full bg-white py-16 px-4'>
          <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
            <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
            <div className='flex flex-col justify-center'>
              <p className='text-[#00df9a] font-bold '>DevSecOps & Cloud Computing</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>A Journey into DevSecOps and Cloud Computing</h1>
              <p>
              In the relentless pursuit of digital transformation, where innovation and efficiency are paramount, the union of DevSecOps and Cloud Computing emerges as a transformative force shaping the future of software development and infrastructure management. 
              As organizations embrace the agility of cloud environments, the imperative to fortify these digital realms with robust security measures has never been more pronounced. This educational series embarks on my journey through two integral pillars of contemporary technology: DevSecOps and Cloud Computing.
              </p>
              <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3' onClick={()=>navigate('/articles')}>Get Started</button>
            </div>
          </div>
        </div>
      );
}

export default CloudComputing
