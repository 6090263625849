import React from 'react'
import Analytics from '../Components/CloudComputing'

const CloudComputing = () => {
  return (
    <div>
     <Analytics />
    </div>
  )
}

export default CloudComputing