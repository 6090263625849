import React from 'react';
import { FaBookJournalWhills} from "react-icons/fa6";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark, stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DiagramS3 from '../Assets/jenkins&aws.png'
import BackuptoAWS from '../Assets/backup-to-aws.png'
import AWSS3Validation from '../Assets/awss3validation.png'

export default function MySQLBackup() {
    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                  <p className="text-base font-semibold leading-7 text-indigo-600">Jenkins & AWS</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Effortless MySQL Backup and Amazon S3 Uploads: A Jenkins Automation Odyssey</h1>
              </div>
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div>
                  <img src={DiagramS3} alt="" className="h-150 w-100 mx-auto" />

                  <p className="text-justify">

                  The main goal for this article is to develop a Jenkins job that automates the entire sequence: from creating a MySQL backup, transferring it over the internet, to uploading it to Amazon S3.

Picture a user employing Jenkins to trigger this process—taking a MySQL backup and seamlessly uploading it to Amazon S3 via the internet.

Throughout this section, you'll gain insights into MySQL and Amazon S3. Additionally, you'll acquire the skills to integrate these technologies using Docker and execute the complete workflow within Jenkins.

Our overarching objective is to streamline the entire procedure, enabling it to be accomplished with a single click. This simplification will automate the MySQL backup and upload to Amazon S3 seamlessly through Jenkins.
                  </p>
                  
                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Prerequisites</h2>
                  <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                    <li className="flex gap-x-3">
                      <FaBookJournalWhills className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900"> Understanding the steps <a href='/jenkins-docker' className='text-blue-700'>here</a>.</strong> Ensure you have following that article before read this for better understanding. So you'll have the Dockerfile and docker-compose.yml from that article.
                      </span>
                    </li>
                  </ul>

                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">A. Preparation</h2>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">1. Create a MySQL server on Docker.</strong> <br />
                        When it comes to deploying a MySQL server for the previous project, Docker offers a convenient and efficient solution. Begin by enhancing your docker-compose.yml file with the following configuration to set up the MySQL server:<br/>

                        <SyntaxHighlighter children={
                            `db_host:
  container_name: db
  image: mysql/mysql-server:5.7
  environment:
    - "MYSQL_ROOT_PASSWORD=1234"
  volumes:
    - "$PWD/db_data:/var/lib/mysql"
  networks:
    - net`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />  
                            This configuration defines a service named db_host using the mysql/mysql-server:5.7 image. It sets the MySQL root password to "1234" and mounts a volume to persistently store the database data. 
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">2. Install MySQL Client and AWS CLI.</strong> <br />
                        Open the centos7/Dockerfile in your preferred text editor and replace its content with the following configuration:<br/>

                        <SyntaxHighlighter language="Dockerfile" style={stackoverflowDark} className="mt-4" lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }} wrapLines={true}>
{`FROM centos

RUN cd /etc/yum.repos.d/
RUN sed -i 's/mirrorlist/#mirrorlist/g' /etc/yum.repos.d/CentOS-*
RUN sed -i 's|#baseurl=http://mirror.centos.org|baseurl=http://vault.centos.org|g' /etc/yum.repos.d/CentOS-*

RUN yum -y install openssh-server

RUN useradd remote_user && \\
    echo "remote_user:1234" | chpasswd && \\
    mkdir /home/remote_user/.ssh && \\
    chmod 700 /home/remote_user/.ssh

COPY remote-key.pub /home/remote_user/.ssh/authorized_keys

RUN chown remote_user:remote_user -R /home/remote_user/.ssh/ && \\
    chmod 600 /home/remote_user/.ssh/authorized_keys

RUN ssh-keygen -A

RUN yum -y install mysql unzip glibc sudo

RUN curl "https://awscli.amazonaws.com/awscli-exe-linux-aarch64.zip" -o "awscliv2.zip" && \\
    unzip awscliv2.zip && \\
    sudo ./aws/install

CMD /usr/sbin/sshd -D`}
</SyntaxHighlighter> 
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">3. Deploy the MySQL Server.</strong> <br />
                        Execute this following command:<br/>

                        <SyntaxHighlighter children={
                            `xxx@MACJULIAN jenkins-data % docker-compose up -d`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />   
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">4. Create a MySQL Database.</strong> <br />
                        Create a database inside the container that created, (for example: testdb)
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">5. Create a S3 Bucket on AWS.</strong> <br />
                        For creating a bucket you can use <a href='https://docs.aws.amazon.com/cli/latest/reference/s3api/create-bucket.html' className='text-blue-700'>this reference.</a>
                      </span>
                  </p>

                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">B. MySQL Database Backup</h2>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">1. Connect to Remote Host Container.</strong> <br />
                        Our objective is for the remote_host container to establish a connection with the db container and retrieve the MySQL backup stored within it.
                        <SyntaxHighlighter children={
                            `xxx@MACJULIAN jenkins-data % docker exec -ti remote_host bash`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />  
                        <SyntaxHighlighter children={
                            `[root@bd530fa7c133 /]# mysqldump -u root -h db_host -p testdb > /tmp/db.sql`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            This command initiates the MySQL dump process, creating a backup (db.sql) of the specified database (testdb) on the designated host (db_host). The process will prompt you to enter the MySQL root password.
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">2. Set AWS Credentials.</strong> <br />
                        <SyntaxHighlighter children={
                            `export AWS_ACCESS_KEY_ID=BXYAHYHHHH5HHHHHHAWS
export AWS_SECRET_ACCESS_KEY=H1U/HHtfH7HHHH+HHHHaHHj+CzcHHHx0HHHH0HHH
                            `} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />  
                            These commands set the AWS access key ID and secret access key needed for authentication when interacting with Amazon S3.
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">3. Upload Backup to Amazon S3.</strong> <br />
                        <SyntaxHighlighter children={
                            `[root@bd530fa7c133 /]# aws s3 cp /tmp/db.sql s3://jenkinss-mysql-backup/db.sql`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />  
                            This command uses the AWS CLI to upload the MySQL backup (db.sql) to the specified S3 bucket (jenkinss-mysql-backup). The successful upload confirmation will be displayed. Also you can check it inside your Amazon S3 Bucket.
                      </span>
                  </p>
                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">C. Automate the Backup and Upload Process with a Shell Script</h2>
                  <p className='mt-4'>
                      <span>
                      In this section, I'll guide you through the process of creating a shell script to automate MySQL database backups and seamlessly upload them to an Amazon S3 bucket
                        <strong className="font-semibold text-gray-900">1. Create the Shell Script.</strong> <br />
                        Create a shell script named script.sh in the /tmp directory.
                        <SyntaxHighlighter children={
                            `#/bin/bash

DATE=$(date +%H-%M-%S)
DB_UNAME=$1
DB_HOST=$2
DB_PASSWORD=$3
DB_NAME=$4
                            
mysqldump -u $DB_UNAME -h $DB_HOST -p$DB_PASSWORD $DB_NAME > /tmp/db-$DATE.sql
                            `} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                      </span>
                  </p>
                  <p className='mt-4'>
                      <span>
                        <strong className="font-semibold text-gray-900">2. Test the Shell Script.</strong> <br />
                        Make the script executable and test it by providing the necessary parameters.
                        <SyntaxHighlighter children={
                            `[root@bd530fa7c133 /]# chmod +x /tmp/script.sh
[root@bd530fa7c133 /]# /tmp/script.sh root db_host 1234 testdb
                            `} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            If you encounter a "Permission denied" error, granting execute permissions as shown above should resolve the issue.
                            Well Done.
                      </span>
                  </p>
                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">D. Integrate your script with AWS CLI & Jenkins UI</h2>
                  <p className='mt-4'>
                    <strong className="font-semibold text-gray-900">1. Edit your script.</strong> <br />
                      <span>
                        Update your file using this following script:
                        <SyntaxHighlighter children={
                            `#/bin/bash

DATE=$(date +%H-%M-%S)
BACKUP=db-$DATE.sql
DB_UNAME=$1
DB_HOST=$2
DB_PASSWORD=$3
DB_NAME=$4
AWS_SECRET=$5
BUCKET_NAME=$6
                            
                            
mysqldump -u $DB_UNAME -h $DB_HOST -p$DB_PASSWORD $DB_NAME > /tmp/$BACKUP && \\
export AWS_ACCESS_KEY_ID=BXYAHYHHHH5HHHHHHAWS && \\
export AWS_SECRET_ACCESS_KEY=$AWS_SECRET && \\
echo "===UPLOADING YOUR $BACKUP BACKUP" && \\
aws s3 cp /tmp/db-$DATE.sql s3://$BUCKET_NAME/$BACKUP
                            `} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                      </span>
                  </p>
                  <p className='mt-4'>
                    <strong className="font-semibold text-gray-900">2. Create a Jenkins Job.</strong> <br />
                      <span>
                        <ul>
                            <li>- Create a new item called "backup-to-aws" in Jenkins UI using Freestyle Project.</li>
                            <li>- Configure the Job with your script above. </li>
                            <li>-* In <strong>General Section</strong>, click This project is parameterized and Add String Parameter (MYSQL_UNAME, MYSQL_HOST, MYSQL_NAME, AWS_BUCKET_NAME)</li>
                            <li>-* In <strong>Build Environment Section</strong>, click Use secret(s) or file(s) and Add Secret Text (MYSQL_PASSWORD, AWS_SECRET_KEY). Because MYSQL_PASSWORD & AWS_SECRET_KEY are sensitive, you should configure it in Credential first before you add Secret Text.</li>
                            <li>-* In <strong>Build Steps Section</strong>, Execute shell script on remote host using ssh. Make sure you configure SSH site 'remote_user@remote_host:22'.</li>
                            <li>and in the command, use this command: <br/>
                            <SyntaxHighlighter children={
                            `/tmp/script.sh $MYSQL_UNAME $MYSQL_HOST $MYSQL_PASSWORD $MYSQL_NAME $AWS_SECRET_KEY $AWS_BUCKET_NAME
                            `} language='bash' style={stackoverflowLight} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            </li>
                        </ul>
                      </span>
                  </p>

                  <p className='mt-4'>
                    <strong className="font-semibold text-gray-900">3. Build Your Jenkins Job.</strong> <br />
                    Now, build your job. It should be like this:
                    <img src={BackuptoAWS} alt="" className="h-50 w-50 mx-auto" /> <br/>
                    And here is the final result:
                    <SyntaxHighlighter children={
                            `Started by user Cahya Julian
Running as SYSTEM
Building in workspace /var/jenkins_home/workspace/backup-to-aws
[SSH] script:
MYSQL_HOST="db_host"
AWS_SECRET_KEY=**********
MYSQL_PASSWORD=**********
MYSQL_NAME="testdb"
AWS_BUCKET_NAME="jenkinss-mysql-backup"
MYSQL_UNAME="root"
                            
/tmp/script.sh $MYSQL_UNAME $MYSQL_HOST $MYSQL_PASSWORD $MYSQL_NAME $AWS_SECRET_KEY $AWS_BUCKET_NAME
                            
[SSH] executing...
mysqldump: [Warning] Using a password on the command line interface can be insecure.
===UPLOADING YOUR db-10-49-55.sql BACKUP===
Completed 1.8 KiB/1.8 KiB (1.6 KiB/s) with 1 file(s) remaining
upload: ../../tmp/db-10-49-55.sql to s3://jenkinss-mysql-backup/db-10-49-55.sql
                            
[SSH] completed
[SSH] exit-status: 0
                            
Finished: SUCCESS`} language='bash' style={stackoverflowLight} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                  </p>
                  <p className='mt-4'>
                    After you get success messages, you should check in your Amazon S3. It should be like this: 
                    <br/> <br/>
                    <img src={AWSS3Validation} alt="" className="h-150 w-100 mx-auto" />
                    Well Done.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

}