import React from 'react'
import { FaCloudUploadAlt } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";
import { LuNetwork } from "react-icons/lu";
import { GrResources } from "react-icons/gr";
import { CiCloudOn } from "react-icons/ci";
import { RiRemoteControlLine } from "react-icons/ri";
import { SiPrivateinternetaccess } from "react-icons/si";
import { CgCommunity } from "react-icons/cg";
import { MdPublic } from "react-icons/md";


export default function CloudIntro() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
              <p className="text-base font-semibold leading-7 text-indigo-600">Cloud Computing</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Introduction</h1>
          </div>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div>
              <p className="text-justify">
                If we talk about cloud computing, what comes to your mind, brilliant friends, is a place for storing data.
                That's also true. But cloud computing refers to the delivery of computing services including servers, storage, data, networking, application and so on,
                over the internet to offer flexibility in resources and scalability.
                In simpler terms instead of owning and maintaining physical hardware and servers users can access and use computing resources as a service 
                provided by a third-party provider over the internet, often referred to as the "cloud."
                <br/>
                Let's see the definition of Cloud Computing from NIST(National Institute of Standards and Technology) & AWS (Amazon Web Services):
              </p>
              <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Cloud Computing Definition</h2>
              <p className="text-justify mt-8">
                <strong>1. NIST</strong>
                <br/>
                Cloud computing is a model for enabling ubiquitous, convenient, on-demand network access to a shared
                pool of configurable computing resources (e.g., networks, servers, storage, applications, and services) that
                can be rapidly provisioned and released with minimal management effort or service provider interaction.
                This cloud model is composed of five essential characteristics, three service models, and four deployment
                models.                
                <br/>
                <br/>
                <strong>2. AWS</strong>
                <br/>
                Cloud computing is the on-demand delivery of IT resources over the Internet with pay-as-you-go pricing. 
                Instead of buying, owning, and maintaining physical data centers and servers, you can access technology services, 
                such as computing power, storage, and databases, on an as-needed basis from a cloud provider like Amazon Web Services (AWS).   
              </p>
              <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Essential Characteristics</h2>
              <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                <li className="flex gap-x-3">
                  <FaComputer className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">On-demand self-service.</strong> A user can independently set up computing resources, 
                    like server time and network storage, whenever necessary, without needing direct human interaction with each service provider.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LuNetwork className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Broad network access.</strong> hese capabilities are accessible via the network 
                    and can be used through standard methods, allowing compatibility with various client platforms, whether they are lightweight or robust 
                    (e.g., mobile phones, tablets, laptops, and workstations). 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <GrResources className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Resource pooling.</strong> The provider combines computing resources into a shared pool, 
                    serving multiple users through a multi-tenant model. Both physical and virtual resources are flexibly allocated and reassigned based on the changing needs of consumers. 
                    The exact location of these resources may not be known to the customer, but they may have the option to specify a higher-level location (e.g., country, state, or datacenter). 
                    Resources in this context include storage, processing power, memory, and network bandwidth.                  
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CiCloudOn className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Rapid elasticity.</strong> Resources can be easily and flexibly allocated or freed up, sometimes automatically, 
                    to quickly adjust to changes in demand, expanding or contracting as needed. 
                    From the user's perspective, the available capabilities for allocation may seem limitless, allowing them to acquire any amount at any given time.                  
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <RiRemoteControlLine className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Measured service.</strong> Cloud systems automatically manage and enhance the utilization of resources 
                    by employing a measuring capability at a relevant level of abstraction, 
                    tailored to the specific service type (such as storage, processing, bandwidth, and active user accounts). 
                    This enables the monitoring, control, and reporting of resource usage, 
                    offering transparency to both the service provider and the user of the utilized service.                  
                  </span>
                </li>
              </ul>

              <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Deployment Model</h2>
              <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                <li className="flex gap-x-3">
                  <SiPrivateinternetaccess className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Private cloud.</strong> The cloud infrastructure is set up for the sole use of a single organization, 
                    which could include various consumers like different business units within that organization. 
                    Ownership, management, and operation of this infrastructure can be handled by the organization itself, a third-party provider, or a combination of both. 
                    Moreover, this infrastructure might be located either on the organization's premises or off-site.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CgCommunity className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Community cloud.</strong> The cloud infrastructure is allocated for the exclusive use of a defined community of consumers, 
                    representing organizations with shared concerns such as mission, security requirements, policies, and compliance considerations. 
                    Ownership, management, and operation of this infrastructure can be undertaken by one or more organizations within the community, a third-party entity, or a combination of them. 
                    Additionally, this infrastructure may be situated either on the premises of the organizations or off-site.                  
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <MdPublic className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Public cloud.</strong> The cloud infrastructure is made available for unrestricted use by the general public. It could be owned, managed, and operated by a business, 
                    academic institution, government organization, or a combination thereof. 
                    This infrastructure is typically hosted on the premises of the cloud provider.                 
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <FaCloudUploadAlt className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Hybrid cloud.</strong> The cloud infrastructure is a combination of two or more separate cloud infrastructures (private, community, or public) 
                    that maintain their individual identities but are interconnected through standardized or proprietary technology. This technology facilitates the portability of data and applications, 
                    allowing functionalities such as cloud bursting for load balancing between the interconnected clouds.                  </span>
                </li>
              </ul>

              <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Service Model</h2>
              <br/>
              <div className='relative overflow-x-auto mt-4'>
                <table className="w-full text-sm text-center rtl:text-center">
                  <thead>
                    <tr>
                      <th className='border border-slate-300'>Service Model</th>
                      <th className='border border-slate-300'>NIST</th>
                      <th className='border border-slate-300'>AWS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-bold text-center border border-slate-300'>Software as a Service (SaaS)</td>
                      <td className='text-justify p-4 border border-solid'>The capability provided to the consumer is to use the provider’s applications running on a cloud infrastructure2 . The applications are accessible from various client devices through either a thin client interface, such as a web browser (e.g., web-based email), or a program interface. The consumer does not manage or control the underlying cloud infrastructure including network, servers, operating systems, storage, or even individual application capabilities, with the possible exception of limited user specific application configuration settings. </td>
                      <td className='text-justify p-4 border border-solid'>SaaS provides you with a complete product that is run and managed by the service provider. In most cases, people referring to SaaS are referring to end-user applications (such as web-based email). With a SaaS offering you don't have to think about how the service is maintained or how the underlying infrastructure is managed.
                        You only need to think about how you will use that particular software. 
                      </td>
                    </tr>
                    <tr>
                      <td className='font-bold text-center border border-slate-300'>Platform as a Service (PaaS)</td>
                      <td className='text-justify p-4 border border-solid'>The capability provided to the consumer is to deploy onto the cloud infrastructure consumer-created or acquired applications created using programming languages, libraries, services, and tools supported by the provider.3 The consumer does not manage or control the underlying cloud infrastructure including network, servers, operating systems, or storage, but has control over the deployed applications and possibly configuration settings for the application-hosting environment.</td>
                      <td className='text-justify p-4 border border-solid'>PaaS removes the need for you to manage underlying infrastructure (usually hardware and Operating System), and allows you to focus on the deployment and management of your applications. This helps you be more efficient as you don't need to worry about resource procurement, capacity planning, software maintenance, patching, or any of the other undifferentiated heavy lifting involved in running your application.</td>
                    </tr>
                    <tr>
                      <td className='font-bold text-center border border-slate-300'>Infrastructure as a Service (IaaS)</td>
                      <td className='text-justify p-4 border border-solid'>The capability provided to the consumer is to provision processing, storage, networks, and other fundamental computing resources where the consumer is able to deploy and run arbitrary software, which can include operating systems and applications. The consumer does not manage or control the underlying cloud infrastructure but has control over operating systems, storage, and deployed applications; and possibly limited control of select networking components (e.g., host firewalls).</td>
                      <td className='text-justify p-4 border border-solid'>IaaS contains the basic building blocks for cloud IT. It typically provides access to networking features, computer (virtual or on dedicated hardware), and data storage space.
                        IaaS gives you a highest level of flexibility and management control over your IT resources .
                        It is most similar to the existing IT resources with which many IT departments and developers are familiar.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Service Model Comparison</h2>
              <p className="mt-4">
              The difference between the four models is how many components are owned by the user, as in the image below, the bold yellow color indicates that the component is owned by the provider.
              Meanwhile, as with On Premise, the user has full control over the infrastructure.
              </p>
              <div className='relative overflow-x-auto mt-4'>
                <table className="w-full text-sm text-center rtl:text-center">
                  <thead>
                    <tr>
                      <th className="text-center p-4 border border-solid">On Premises</th>
                      <th className="text-center p-4 border border-solid">IaaS</th>
                      <th className="text-center p-4 border border-solid">PaaS</th>
                      <th className="text-center p-4 border border-solid">SaaS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Application</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Application</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Application</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Application</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Data</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Data</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Data</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Data</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Runtime</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Runtime</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Runtime</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Runtime</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Middleware</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Middleware</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Middleware</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Middleware</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">OS</td>
                      <td className="text-center p-4 border border-solid bg-yellow-50">OS</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">OS</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">OS</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Virtualization</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Virtualization</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Virtualization</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Virtualization</td>
                    </tr>
                    <tr>
                      <td className="text-center p-4 border border-solid bg-yellow-50">Physical</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Physical</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Physical</td>
                      <td className="text-center p-4 border border-solid bg-yellow-300">Physical</td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

