import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

export default function Navbar ({menuOpen, setMenuOpen}) {
  const [nav, setNav] = useState(false)
  const handleNav=()=>{
    setNav(!nav)
  }
  return (
    <div className='flex justify-between items-center h-24 mx-auto p-4 text-white'>
        <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Brilliant. </h1>
        <ul className='hidden md:flex'>
          <li className='p-4'>
            <a href='/'>Home</a>
          </li>
          <li className='p-4'>
            <a href='/articles'>Articles</a>
          </li>
          <li className='p-4'>
            <a href='https://www.linkedin.com/in/cahya-julian-03b188121/'>Author</a>
          </li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
        </div>
        <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#080F5B] ease-in-out duration-500' : 'fixed left-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>Brilliant. </h1>
          <ul className='uppercase p-4'>
            <li className='p-4 border-b border-gray-600'>
              <a href='/'>Home</a>
            </li>
            <li className='p-4 border-b border-gray-600'>
              <a href='/learning-journey-started'>Articles</a>
            </li>
            <li className='p-4 border-b border-gray-600'>
              <a href='https://www.linkedin.com/in/cahya-julian-03b188121/'>Author</a>
            </li>
          </ul>
        </div>
    </div>
  )
}