import React from 'react'
import Hero from '../Components/Hero'

const Home = () => {
  return (
    <div>
        <Hero />
    </div>
  )
}


export default Home

