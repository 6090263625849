import React from 'react';
import { FaComputer, FaJenkins, FaServer } from "react-icons/fa6";
import { CiCircleCheck } from "react-icons/ci";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function JenkinsDockeSSH() {
    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                  <p className="text-base font-semibold leading-7 text-indigo-600">Jenkins</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Running Jenkins Jobs on a Docker Remote Host Through SSH</h1>
              </div>
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div>
                  <p className="text-justify">
                  In the realm of continuous integration and automation, Jenkins stands tall as a powerful tool for orchestrating workflows and executing tasks. One common scenario involves running Jenkins jobs on a remote Docker host through SSH, 
                  allowing for efficient deployment and execution of commands on a target machine. Let's dive into the step-by-step process of setting up and running a Jenkins job that interacts with a Dockerized remote host.
                  </p>
                  
                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Prerequisites</h2>
                  <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                    <li className="flex gap-x-3">
                      <FaComputer className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Jenkins Server.</strong> Ensure Jenkins is installed and running on your server.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <FaServer className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Docker Remote Host.</strong> Set up a remote machine with Docker installed and accessible via SSH. You can use remote host that we configure in <a href='/jenkins-docker' className='text-blue-700'>here.</a>
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <FaJenkins className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Jenkins SSH Plugin.</strong> Install the SSH Plugin in Jenkins to enable remote execution. Here is the <a href='https://plugins.jenkins.io/ssh/' className='text-blue-700'>plugin</a> that you need to configure.                  
                      </span>
                    </li>
                  </ul>

                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Steps</h2>
                  <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Create a New Jenkins Item.</strong> <br />
                        1. Navigate to your Jenkins dashboard.<br/>
                        2. Click on "New Item" to create a new job.
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Choose Freestyle Project.</strong> <br />
                        1. Select "Freestyle project" as the job type.<br/>
                        2. Enter a desired name for your project and click "OK.".
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Configure Build Environment.</strong> <br />
                        1. Scroll down to the "Build Environment" section.<br/>
                        2. Check the option "Execute shell script on remote host using ssh.".
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Configure SSH Site.</strong> <br />
                        1. Click on the "SSH Site" drop-down and select the pre-configured SSH site for your Docker remote host.<br/>
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Write Your Command.</strong> <br />
                        1. In the "Command" text area, enter the commands you want to execute on the remote Docker host.<br/>
                        2. Example Command: <br/>
                        <SyntaxHighlighter children={
                            `NAME=JULIAN
echo "Hello, $NAME. Current date time is $(date)" > /tmp/remote-file`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />   
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Save and Run.</strong> <br />
                        1. Save your Jenkins job configuration.<br/>
                        2. Click "Build Now" to run the job.
                      </span>
                    </li>
                    <li className="flex gap-x-12">
                      <CiCircleCheck className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Verify on Remote Host.</strong> <br />
                        1. SSH into your Docker remote host and check the content of the /tmp/remote-file file.<br/>
                        2. Example: <br/>
                        <SyntaxHighlighter children={
                            `[root@f0314aae51f3 /]# cat /tmp/remote-file
Hello, JULIAN. Current date time is Wed Feb 21 13:59:11 UTC 2024`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />  
                      </span>
                    </li>
                  </ul>

                  <h2 className='mt-8 text-2xl font-bold tracking-tight text-gray-900'>Conclusion</h2>
                  <p className='mt-8 space-y-8 text-justify'>
                    Running Jenkins jobs on a Docker remote host through SSH provides a seamless way to execute commands and scripts on a target machine. By integrating Jenkins with Docker and SSH, you can enhance your automation workflows and streamline deployment processes. This approach is particularly valuable in scenarios where Dockerized environments are utilized, offering flexibility, scalability, and ease of management. Embrace the power of Jenkins, Docker, and SSH to elevate your automation game and achieve efficient continuous integration and deployment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

}