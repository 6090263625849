import React from 'react';
import { FaComputer } from "react-icons/fa6";
import { LuNetwork } from "react-icons/lu";
import { GrResources } from "react-icons/gr";
import { CiCloudOn } from "react-icons/ci";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function JenkinsDocker() {
    return (
        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
            </svg>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                  <p className="text-base font-semibold leading-7 text-indigo-600">Jenkins</p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Executing Jobs on Remote Machines</h1>
              </div>
            </div>
            <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div>
                  <p className="text-justify">
                    In this tutorial, we'll explore how to execute jobs on remote machines. Imagine you have two servers, and you want to run jobs on a remote machine, say server two. 
                    The only available connection method is SSH. How can you achieve this?
                    <br/>
                    To address this scenario, you have two options:
                  </p>
                  <p className="text-justify mt-4">
                    <strong>1. Create Another Virtual Machine</strong>
                    <br/>
                        In this approach, you set up a separate virtual machine to serve as the remote host. This involves creating a new virtual machine in your virtualization environment (using tools like VirtualBox or VMware). Once set up, configure Jenkins to connect to this remote host using SSH, allowing you to run jobs on the remote virtual machine while keeping Jenkins and the execution environment separate.                
                    <br/>
                    <br/>
                    <strong>2. Create Another Docker Container</strong>
                    <br/>
                        Alternatively, you can create an additional Docker container to act as the remote execution environment. The existing Jenkins container connects to this new container using SSH. This option is more lightweight, as it doesn't require provisioning a new virtual machine. The additional Docker container can be created on the same virtual machine where Jenkins is running.
                    </p>
                  <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">Considerations</h2>
                  <ul className="mt-8 space-y-8 text-gray-600 text-justify">
                    <li className="flex gap-x-3">
                      <FaComputer className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Isolation</strong> The first option provides stronger isolation between Jenkins and the remote execution environment since they run on different virtual machines. The second option, using Docker containers, provides isolation at the container level but not at the virtual machine level
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <LuNetwork className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Resource Overhead</strong> Docker containers typically have less overhead compared to running separate virtual machines, making them faster to start and use fewer resources. 
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <GrResources className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Dependency Management</strong> Using Docker allows you to encapsulate dependencies and configurations within containers, facilitating easier management and versioning of your environment.                  
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <CiCloudOn className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      <span>
                        <strong className="font-semibold text-gray-900">Complexity</strong> The first option with a separate virtual machine might involve more setup and maintenance effort, while the second option with Docker containers is often quicker to set up and tear down.                  
                      </span>
                    </li>
                  </ul>

                    <p className='text-justify mt-4'>
                        Now, let's proceed with the second option. Start by creating a folder to store the files needed for this demonstration. In this example, we'll create a Docker container using CentOS.
                    </p>
                    <br />
                    <strong>1. Create Dockerfile, Generate an SSH key & Services</strong>
                    <p className='mt-4'>
                      Inside jenkins-data directory, create a docker-compose.yml
                    </p>
                    <SyntaxHighlighter 
                      children={
                        `version: '3'
services:
  jenkins:
    container_name: jenkins
    image: jenkins/jenkins
    ports:
      - "8080:8080"
    volumes:
      - "$PWD/jenkins_home:/var/jenkins_home"
    networks:
      - net
  remote_host:
    container_name: remote_host
    image: remote-host-image
    build:
      context: centos7
    networks:
      - net
networks:
  net:`
                      }
                    />
                    <div className='text-justify mt-4'>
                    <p className='mt-4'>
                      After that create another image using Dockerfile. Here we will create it in centos7 directory.
                    </p>
                        <SyntaxHighlighter children={
                        `xxx@MACJULIAN jenkins-data % mkdir centos7 \nxxx@MACJULIAN jenkins-data % cd centos7 \nxxx@MACJULIAN centos7 %`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                        wrapLines={true} />
                        <p className='mt-4'>
                            To create a Docker image, you need a Dockerfile. The following Dockerfile sets up an SSH server and user:
                        </p>
                        <SyntaxHighlighter children={`FROM centos

RUN cd /etc/yum.repos.d/
RUN sed -i 's/mirrorlist/#mirrorlist/g' /etc/yum.repos.d/CentOS-*
RUN sed -i 's|#baseurl=http://mirror.centos.org|baseurl=http://vault.centos.org|g' /etc/yum.repos.d/CentOS-*

RUN yum -y install openssh-server

RUN useradd remote_user && \\
    echo "remote_user:1234" | chpasswd && \\
    mkdir /home/remote_user/.ssh && \\
    chmod 700 /home/remote_user/.ssh

COPY remote-key.pub /home/remote_user/.ssh/authorized_keys

RUN chown remote_user:remote_user -R /home/remote_user/.ssh/ && \\
    chmod 600 /home/remote_user/.ssh/authorized_keys

RUN ssh-keygen -A

CMD /usr/sbin/sshd -D`}   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
wrapLines={true} language='docker' style={stackoverflowDark} className='mt-4 overflow-auto'/>
                        <p className='mt-4'>
                            After creating the Dockerfile, generate an SSH key:
                        </p>
                        <SyntaxHighlighter children={`xxx@MACJULIAN centos7 % ssh-keygen -f remote-key Generating `} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                        wrapLines={true} />
                        <SyntaxHighlighter children={
                        `public/private rsa key pair.
Enter passphrase (empty for no passphrase):
Enter same passphrase again:
Your identification has been saved in remote-key
Your public key has been saved in remote-key.pub
The key fingerprint is:
SHA256:ye/NohkzB1tY7KxtKFLmN8oqtckG4HvvbCR6r2ZQduA xxx@MACJULIAN.local
The key's randomart image is:
+---[RSA 3072]----+
|                 |
|   .     .       |
|  . .     o      |
|.  E . . *       |
|..o . o S +      |
| o...=   O       |
|  +++oo X =      |
| o.=*+ + X.o     |
|  =+B*o o...o    |
+----[SHA256]-----+
xxx@MACJULIAN centos7 % ls -l
total 24
-rw-r--r--@ 1 xxx  staff   204 Feb 18 21:24 Dockerfile
-rw-------@ 1 xxx  staff  2622 Feb 18 21:26 remote-key
-rw-r--r--@ 1 xxx  staff   583 Feb 18 21:26 remote-key.pub`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                        wrapLines={true} />
                        <p className='mt-4'>
                            Move back to the `jenkins-data` directory and create the services using:
                        </p>
                        <SyntaxHighlighter children={`xxx@MACJULIAN jenkins-data % docker-compose up -d
[+] Running 3/3
 ✔ Network jenkins-data_net  Cr...                            0.0s
 ✔ Container jenkins         Started                          0.7s
 ✔ Container remote_host     Start...                         0.5s`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
 wrapLines={true} />
                    </div>
                    <br />
                    <br />
                    <strong>2. Executing Jenkins Jobs on a Remote Container through Docker</strong>
                    <div className='text-justify mt-4'>
                        <p>
                            Now, we'll walk through the process of executing Jenkins jobs on a remote container named `remote_host` using a Jenkins container. Follow the steps below to establish a secure connection and troubleshoot common issues:
                        </p>
                        <ul>
                          <li className="gap-x-12">
                            a. Open a terminal and enter the following command to access the Jenkins container and inside the Jenkins container, attempt to SSH into the remote host using the following command:
                            <SyntaxHighlighter children={
                            `xxx@MACJULIAN jenkins-data % docker exec -it jenkins bash`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            <SyntaxHighlighter children={
                            `jenkins@83ad3ea0e39e:/$ ssh remote_user@remote_host
remote_user@remote_host's password:
"System is booting up. Unprivileged users are not permitted to log in yet. Please come back later. For technical details, see pam_nologin(8)."
Connection closed by 172.23.0.2 port 22`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            If you encounter failed login attempts like that, follow these steps and do `docker exec -it jenkins bash` again :
                            <SyntaxHighlighter children={
                            `xxx@MACJULIAN jenkins-data % docker exec remote_host bash -c "rm -rf /run/nologin"`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            <SyntaxHighlighter children={
                                `xxx@MACJULIAN jenkins-data % docker exec -it jenkins bash`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            <SyntaxHighlighter children={
                            `jenkins@83ad3ea0e39e:/$ ssh remote_user@remote_host
remote_user@remote_host's password:
Permission denied, please try again.
remote_user@remote_host's password:
Last login: Mon Feb 19 07:50:35 2024 from 172.23.0.3`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                            wrapLines={true} />
                            Done.
                          </li>
                        <li className="gap-x-12">
                          b. If you want to access the remote_host without fill in the password, you can following these steps:
                          <SyntaxHighlighter children={
                          `xxx@MACJULIAN jenkins-data % docker cp centos7/remote-key
jenkins:/tmp/remote-key
Successfully copied 4.61kB to jenkins:/tmp/remote-key`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                          wrapLines={true} />
                          finally, do this command:
                          <SyntaxHighlighter children={
                          `jenkins@83ad3ea0e39e:/$ ssh -i /tmp/remote-key remote_user@remote_host
Last failed login: Mon Feb 19 07:50:26 UTC 2024 from 172.23.0.3 on ssh:notty
There were 2 failed login attempts since the last successful login.
Last login: Mon Feb 19 07:50:05 2024 from 172.23.0.3`} language='bash' style={stackoverflowDark} className='mt-4'   lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                          wrapLines={true} /> 
                          </li>
                        </ul>
                        <p>
                        </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

}