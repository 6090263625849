import Julian from '../Assets/cahya.jpg'
const posts = [
    {
      id: 1,
      title: 'What is Cloud Computing?',
      href: '/cloud-intro',
      description:
        'If we talk about cloud computing, what comes to your mind, brilliant friends, is a place for storing data. That`s also true. But cloud computing refers to the delivery of computing services including servers, storage, data, networking, ...',
      date: 'Dec 17th, 2023',
      datetime: '2020-03-16',
      category: { title: 'Cloud', href: '/cloud-computing' },
      author: {
        name: 'Cahya Julian',
        role: 'Tech Enthusiast',
        href: 'https://www.linkedin.com/in/cahya-julian-03b188121/',
        imageUrl:
          Julian,
      },
    },
    {
      id: 2,
      title: 'Executing Jobs on Remote Machines',
      href: '/jenkins-docker',
      description:
        `In this tutorial, we'll explore how to execute jobs on remote machines. Imagine you have two servers, and you want to run jobs on a remote machine, say server two.`,
      date: 'Feb 21st, 2024',
      datetime: '2024-02-21',
      category: { title: 'Docker & Jenkins', href: '/jenkins-docker' },
      author: {
        name: 'Cahya Julian',
        role: 'Tech Enthusiast',
        href: 'https://www.linkedin.com/in/cahya-julian-03b188121/',
        imageUrl:
          Julian,
      },
    },
    {
      id: 3,
      title: 'Running Jenkins Jobs on a Docker Remote Host Through SSH',
      href: '/docker-ssh',
      description:
        `In the realm of continuous integration and automation, Jenkins stands tall as a powerful tool for orchestrating workflows and executing tasks.`,
      date: 'Feb 23rd, 2024',
      datetime: '2024-02-23',
      category: { title: 'Docker & Jenkins', href: '/docker-ssh' },
      author: {
        name: 'Cahya Julian',
        role: 'Tech Enthusiast',
        href: 'https://www.linkedin.com/in/cahya-julian-03b188121/',
        imageUrl:
          Julian,
      },
    },
    {
      id: 4,
      title: 'Effortless MySQL Backup and Amazon S3 Uploads: A Jenkins Automation Odyssey',
      href: '/mysql-backup',
      description:
        `The main goal for this article is to develop a Jenkins job that automates the entire sequence: from creating a MySQL backup, transferring it over the internet, to uploading it to Amazon S3.`,
      date: 'Feb 25th, 2024',
      datetime: '2024-02-25',
      category: { title: 'Jenkins & AWS', href: '/mysql-backup' },
      author: {
        name: 'Cahya Julian',
        role: 'Tech Enthusiast',
        href: 'https://www.linkedin.com/in/cahya-julian-03b188121/',
        imageUrl:
          Julian,
      },
    }
    // More posts...
  ]
  
  export default function BlogSections() {
    const sortedPosts = [...posts].sort((a, b) => b.id - a.id);
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From Brilliant's blog</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Learn DevSecOps & Cloud Computing.
            </p>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {sortedPosts.map((post) => (
              <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <a
                    href={post.category.href}
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </a>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href={post.author.href}>
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                    <p className="text-gray-600">{post.author.role}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    )
  }